<template>
	<ion-page>
		<A-header :title="list.title" :defaultHref="{name: 'Lists'}" :enable-delete="true" @on-delete="deleteList()" @on-title-click="renameList()" />
		
		<ion-content :fullscreen="true">
			<A-collapse-header :title="list.title" @click="renameList()" />
		
			<ion-list lines="none">				
				<ion-item-sliding v-for="item in openedItems" :key="`item-${item.id}`">
					<ion-item>
						<div slot="start" style="position: relative">
							<ion-checkbox @update:modelValue="toggleItemCheck($event, item.id)" :modelValue="item.is_checked" :disabled="!isConnected || item._isEditing" />
						</div>

						<ion-input v-model="item.name" @keydown.enter="$refs[`item-${item.id}-input`].$el.setBlur()" @ionBlur="editItem(item)" placeholder="Finish presentation" autocapitalize="on" :clear-input="false" :disabled="!isConnected" :ref="`item-${item.id}-input`" />
						<!-- <ion-label v-else @click="enableItemEdit(item.id)">{{ item.name }}</ion-label> -->

						<ion-badge @click.prevent="changePriority(item.id)" :class="`priority-${item.priority}`">{{ priorities[item.priority] }}</ion-badge>
					</ion-item>

					<ion-item-options side="end">
						<ion-item-option color="danger" @click="deleteItem(item.id)" :disabled="!isConnected">
							<ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>

				<ion-item>
					<div slot="start" style="position: relative">
						<ion-checkbox disabled/>
					</div>

					<ion-input v-model="newItemName" @keydown.enter="addItem()" placeholder="New task" autocapitalize="on" :clear-input="false" :disabled="!isConnected" />
				</ion-item>

			</ion-list>

			<ion-list lines="none" v-if="finishedItems.length">
				<ion-item class="finished-tasks-title-item">
					<ion-label>Finished tasks</ion-label>
					<ion-icon :icon="trashOutline" class="delete" slot="end" @click="deleteAllCheckedItems()" />
				</ion-item>

				<ion-item-sliding v-for="item in finishedItems" :key="`item-${item.id}`">
					<ion-item class="finished">
						<div slot="start" style="position: relative">
							<ion-checkbox @update:modelValue="toggleItemCheck($event, item.id)" :modelValue="item.is_checked" :disabled="!isConnected || item._isEditing" />
						</div>

						<ion-label>{{ item.name }}</ion-label>
					</ion-item>

					<ion-item-options side="end">
						<ion-item-option color="danger" @click="deleteItem(item.id)" :disabled="!isConnected">
							<ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>
			</ion-list>

			<A-footer-safearea />
		</ion-content>
	</ion-page>
</template>
<script>
import AHeader 			from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 	from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import AFooterSafearea  from '@/plugins/appshoppinglist/layout/a-footer-safearea.vue'
import { trashOutline} 	from 'ionicons/icons'
import { Network } 		from '@capacitor/network'
import { pickerController, alertController } from "@ionic/vue"

export default {
	components: {
		AHeader,
		ACollapseHeader,
		AFooterSafearea
	},

	data() {
		return {
			trashOutline,
			isConnected: true,
			newItemName: '',
			priorityColumn: [
				{ text: 'None', value: 0 },
				{ text: 'Optional', value: 1 },
				{ text: 'Should', value: 2 },
				{ text: 'Normal', value: 3 },
				{ text: 'Important', value: 4 },
				{ text: 'High priority', value: 5 }
			],
			priorities: {
				0: 'None',
				1: 'Optional',
				2: 'Should',
				3: 'Normal',
				4: 'Important',
				5: 'High priority',
			}
		}
	},

	computed: {
		list() {
			return this.$store.getters['shoppingList/lists'].find(list => list.id == this.$route.params.id) || {}
		},

		openedItems() {
			if (!this.list || !this.list.items) return []
			
			return this.list.items.filter(item => item.is_checked != true)
		},

		finishedItems() {
			if (!this.list || !this.list.items) return []

			return this.list.items.filter(item => item.is_checked == true)
		}
	},

	async created() {
		Network.addListener('networkStatusChange', status => {
			this.isConnected = status.connected
		})

		await Network.getStatus()
			.then(status => this.isConnected = status.connected)
	},

	methods: {
		async renameList() {
			if (!this.isConnected) return
			
			const alert = await alertController.create({
				header: 'Rename list',
				inputs: [{
					name: 'title',
					type: 'text',
					placeholder: 'Family dinner',
					value: this.list.title
				}],
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},
				{
					text: 'Create',
					handler: async(value) => {
						const list = this.list
						list.title = value.title.length ? value.title : 'Unnamed list'
						await this.updateList(list)
					}
				}]
			})

			return alert.present()
		},

		updateList(list) {
			this.$store.dispatch('shoppingList/updateList', list)
		},

		async deleteList() {
			const alert = await alertController.create({
				header: 'Are you sure?',
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				}, {
					text: 'Confirm',
					role: 'confirm'
				}]
			})

			await alert.present()

			const { role } = await alert.onDidDismiss()
			if (role == 'confirm') {
				this.$router.go(-1)
				this.$store.dispatch('shoppingList/deleteList', this.$route.params.id)
			}
		},

		addItem() {
			if (!this.newItemName.length) return
			
			this.$store.dispatch('shoppingList/createItem', {
				listId: this.$route.params.id,
				item: {
					name: this.newItemName,
					priority: 0,
					is_checked: false
				}
			})

			this.newItemName = ''
		},

		deleteAllCheckedItems() {
			this.$store.dispatch('shoppingList/deleteCheckedItems', this.list.id)
		},

		toggleItemCheck(value, itemId) {
			this.$store.dispatch('shoppingList/updateItem', {
				listId: this.$route.params.id,
				item: {
					id: itemId,
					is_checked: value
				}
			})
		},

		editItem(item) {
			this.$store.dispatch('shoppingList/updateItem', {
				listId: this.$route.params.id,
				item: item
			}).then(() => this.$forceUpdate())
		},

		deleteItem(itemId) {
			this.$store.dispatch('shoppingList/deleteItem', {
				listId: this.$route.params.id,
				itemId: itemId
			})
		},

		async changePriority(itemId) {
			if (!this.isConnected) return

			const item = this.list.items.find(item => item.id == itemId)

			let valueIndex
			
			this.priorityColumn.forEach((option, index) => {
				if (option.value == item.priority) valueIndex = index
			})

			const picker = await pickerController.create({
				columns: [{
					name: "priority",
					selectedIndex: valueIndex,
					options: this.priorityColumn
				}],
				buttons: [{
					text: "Cancel",
					role: "cancel",
				},
				{
					text: "Confirm",
					handler: (value) => {
						this.editItem({
							id: item.id, 
							priority: value.priority.value
						})
					}
				}]
			})

			picker.columns.forEach(column => {
				column.options.forEach(element => {
					delete element.selected
					delete element.duration
					delete element.transform
				})
			})

			await picker.present()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-list
	margin-top: 10px
	margin-bottom: 15px

	ion-input
		&.has-focus
			border-bottom: 1px solid var(--ion-color-primary)

h4
	margin-left: 16px
	margin-top: 20px
	margin-bottom: 15px

ion-item.finished-tasks-title-item
	margin-top: 40px
	margin-bottom: 10px

	ion-label
		font-size: 20px

ion-item.finished
	ion-label
		opacity: 0.5
</style>